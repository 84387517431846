$gray: #34495e;
$darkgray: #2c3e50;

.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 30px;
  overflow: hidden;
  border: 1px solid black;
  border-radius: 0;

  select {
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;

    flex: 1;
    padding: 0 1em;
    color: black;
    background-color: white;
    background-image: none;
    cursor: pointer;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;

    /* Remove IE arrow */
    &::-ms-expand {
      display: none;
    }
  }

  /* Arrow */
  &::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px;
    background-color: white;
    transition: .25s all ease;
    pointer-events: none;
    color: grey;
  }

  /* Transition */
  &:hover::after {
    color: black;
  }
}
