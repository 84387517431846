.check-box {
  //transform: scale(2);
}

input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 45px;
  height: 20px;
  background: #fff;
  border: 2px solid #a6a6a6;;
  border-radius: 50px;
  //box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.4s;
}

input:checked[type="checkbox"] {
  background: #0078d7;
  border-color: #0078d7;
}

input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  top: 2px;
  left: 2px;
  background: #666;
  border-radius: 50%;
  //box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  transition: 0.4s;
}

input:checked[type="checkbox"]::after {
  left: 27px;
  background: white;
}
